import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";
import s from "./flows.module.scss";

import {
  deleteFlow,
  fetchFlowsList,
  flowSelector,
} from "../../../redux/slices/flowSlice";
import CustomSkeleton from "../../../components/CustomSkeleton/CustomSkeleton";
import MultiSelect from "../../../components/Blocks/Selects/MultiSelect/MultiSelect";
import GlobalSvgSelector from "../../../components/GlobalSvgSelector/GlobalSvgSelector";
import FlowTd from "./FlowTd/FlowTd";
import { applicationSelector } from "../../../redux/slices/applicationSlice";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../redux/slices/globalDataSlice";
import { redirectorSelector } from "../../../redux/slices/redirectorSlice";
import { setTopUpBalanceActive } from "../../../redux/slices/financeSlice";
import EmptyList from "../../../components/Blocks/EmptyList/EmptyList";
import useGetSearchParams from "../../../hooks/useGetSearchParams";
import {
  useSearchParamsOptions,
  useSelectOptions,
} from "../../../utils/helpers/useSelectHelpers";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  application_name,
  domain_address,
  geo,
  page,
  search,
  sub7,
} from "../Statistics/ClicksStats/ClicksStats";
import useSetSearchParams from "../../../hooks/useSetSearchParams";
import { fetchFlowFilters } from "../../../api/helpers/fetchFilters";
import { getTableDefaultParams } from "../../../api/helpers/scripts";
import {
  INVALID_PAGE_ERROR,
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../utils/vars/staticVariables";
import { params } from "../../../utils/vars/params";
import GradientSearch from "../../../components/partials/GradientSearch/GradientSearch";
import { useSnackbar } from "notistack";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import { hasNonEmptyParams } from "../../../utils/helpers/hasNoneEmptyParams";
import FilterApplicationSelect from "../../../components/Blocks/Selects/FilterApplicationSelect";
import { cleanDomain } from "../../../utils/helpers/cleanDomain";
import GeoSelect from "../../../components/Blocks/Selects/GeoSelect";
import {getIsAllowedStatisticsAccess, getIsStaff, getUser} from "../../../storage/storage";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../components/partials/LanguageDropdown/LanguageDropdown";
import {managementSelector} from "../../../redux/slices/managementSlice";

const Flows = () => {
  const [selectedGeos, setSelectedGeos] = useState([]);

  const dispatch = useDispatch();

  const { isPartner } = useSelector((state) => state.auth);
  const { redirectorsList } = useSelector(redirectorSelector);
  const {
    domainsList,
    geoList,
    geoListPending,
    isCollapsedSidebar,
    isAdaptiveMenuActive,
  } = useSelector(globalDataSelector);

  const { appList } = useSelector(applicationSelector);
  const { standardClickPriceValue } = useSelector(managementSelector);

  const { flowsListPending, flowsList, totalCount, duplicateFlowPending } =
    useSelector(flowSelector);

  const showSnackbar = useCustomSnackbar();
  const { closeSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { search: searchValue, page: pageValue } = useGetSearchParams();

  const updateSearchParams = useSetSearchParams(setSearchParams);

  const [selectedDomains, selectedSub7, selectedApplications] =
    useSearchParamsOptions(searchParams, [
      domain_address,
      sub7,
      application_name,
    ]);

  const domainsListOptions = useSelectOptions(
    domainsList,
    "address",
    "address",
  );

  const sub7ListOptions = useSelectOptions(redirectorsList, "sub7", "sub7");

  const geoListOptions = useMemo(() => {
    return geoList.map((item) => ({
      label: item.name,
      value: item.code,
      icon: item.flag,
    }));
  }, [geoList]);

  const clearDomainParam = useCallback(() => {
    updateSearchParams(domain_address, "", true)
  },[updateSearchParams])

  useEffect(() => {
    const domainParam = searchParams.get("domain_address");

    if (domainParam) {
      const domains = domainParam.split(",");
      const cleanedDomains = domains.map((domain) =>
        cleanDomain(decodeURIComponent(domain)),
      );

      // Якщо очищені домени не збігаються з оригінальними, оновлюємо параметри
      if (cleanedDomains.join(",") !== domains.join(",")) {
        updateSearchParams("domain_address", cleanedDomains.join(","));
      }
    }
  }, [searchParams, updateSearchParams]);

  useEffect(() => {
    const newGeoListOptions = searchParams
      .get(geo)
      ?.split(",")
      .filter((value) => value !== "")
      .map((value) => {
        return geoListOptions.find((item) => item.value === value);
      });

    setSelectedGeos(newGeoListOptions);
  }, [searchParams, geoListOptions]);

  const memorizedGeosList = useMemo(() => {
    return geoList;
  }, [geoList]);

  const appListOptions = useMemo(() => {
    return appList.map((item) => ({
      label: item.name,
      value: item.name,
      is_alive: getIsStaff() ? item.is_alive : true,
      name: "app_list",
      icon: item.icon,
    }));
  }, [appList]);

  const [name, setName] = useDebounce((value) => {
    if (value) {
      updateSearchParams(search, value);
    } else {
      updateSearchParams(search, value, true);
    }
  }, 600);

  useEffect(() => {
    fetchFlowFilters(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!searchValue) {
      setName("");
    } else {
      setName(searchValue);
    }
  }, [searchParams]);

  const fetchData = useCallback(() => {
    const promise = dispatch(
      fetchFlowsList(
        getTableDefaultParams({
          searchParams,
          defaultPageSize: 25,
        }),
      ),
    );

    promise.unwrap().catch((err) => {
      if (err?.message === INVALID_PAGE_ERROR) {
        updateSearchParams(page, 1);
      }
    });

    return promise;
  }, [dispatch, searchParams, updateSearchParams]);

  useEffect(() => {
    const promise = fetchData();

    return () => {
      promise.abort();
    };
  }, [dispatch, searchParams]);

  const onDeleteHandler = useCallback(
    async (id, domain) => {
      const loadingSnackbarKey = showSnackbar(
        t("notif_delete_flow"),
        snackbar_info,
        true,
      );

      const flowDeleteResult = await dispatch(deleteFlow({ id }));

      if (flowDeleteResult?.payload?.status === 200) {
        fetchData();
        closeSnackbar(loadingSnackbarKey);
        showSnackbar(
          `${domain} ${t("notif_was_deleted")}`,
          snackbar_success,
        );
      } else {
        closeSnackbar(loadingSnackbarKey);
        showSnackbar(
          `${t("notif_failed_to_delete")} ${domain} ${t("notif_lowercase_flow")}`,
          snackbar_error,
        );
      }
    },
    [closeSnackbar, dispatch, fetchData, showSnackbar],
  );

  const clearSearchParams = () => {
    setSearchParams(new URLSearchParams());
  };

  return (
    <div
      className={`${s.flowsWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>

          <div className={s.changingElements}>
            <GradientSearch value={name} setValue={setName} />

            <Link to="/my-flows/create-flow" className={s.primaryBtn}>
              <GlobalSvgSelector id="plus-button" />
              {t("create_flow")}
            </Link>
          </div>
        </div>
      </div>

      <div className={s.pageContent}>
        <div className={s.filtersWrapper}>
          <div className={s.filters}>
            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="domain-select-icon" />
              </div>
              <MultiSelect
                extractDomain={true}
                isClearable={true}
                value={selectedDomains}
                options={domainsListOptions}
                setSelectedOptions={(values) =>
                  updateSearchParams(
                    domain_address,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("domain_placeholder")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="app-select-icon" />
              </div>
              <FilterApplicationSelect
                isClearable={true}
                value={selectedApplications}
                options={appListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    application_name,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("application_placeholder")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="geo-select-icon" />
              </div>
              <GeoSelect
                isClearable={true}
                value={selectedGeos}
                options={geoListOptions}
                onChange={(values) =>
                  updateSearchParams(
                    geo,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder={t("geo_placeholder")}
                isMulti={true}
              />
            </div>

            <div className={s.selectWrapper}>
              <div className={s.selectIconWrapper}>
                <GlobalSvgSelector id="sub7-select-icon" />
              </div>
              <MultiSelect
                isClearable={true}
                value={selectedSub7}
                options={sub7ListOptions}
                setSelectedOptions={(values) =>
                  updateSearchParams(
                    sub7,
                    values.map((el) => el.value).join(","),
                  )
                }
                placeholder="Sub7"
                isMulti={true}
              />
            </div>

            {hasNonEmptyParams(searchParams) && (
              <div className={s.cleanBtnContainer}>
                <button className={s.blueBorderBtn} onClick={clearSearchParams}>
                  {t("clean_button")}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className={s.pageInnerContent}>
          {totalCount === 0 && !flowsListPending ? (
            <EmptyList />
          ) : flowsListPending || geoListPending ? (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name_column")}</th>
                    <th>{t("domain_column")}</th>
                    <th>{t("geo_column")}</th>
                    <th>{t("cloaca_column")}</th>
                    <th>{t("applications_column")}</th>
                    <th></th>
                  </tr>
                </thead>
              </table>
              <div className={s.skeletonWrapper}>
                <CustomSkeleton styles={s.skeletonTable} />
              </div>
            </section>
          ) : (
            <section className={s.body}>
              <table>
                <thead>
                  <tr>
                    <th>{t("name_column")}</th>
                    <th>{t("domain_column")}</th>
                    <th>{t("geo_column")}</th>
                    <th>{t("cloaca_column")}</th>
                    <th>{t("applications_column")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {flowsList.map((item) => (
                    <FlowTd
                        clearDomainParam={clearDomainParam}
                      geosList={memorizedGeosList}
                      key={item.id}
                      getFlows={fetchData}
                      isPartner={isPartner}
                      duplicateFlowPending={duplicateFlowPending}
                      item={item}
                      onDeleteHandler={onDeleteHandler}
                    />
                  ))}
                </tbody>
              </table>
            </section>
          )}
        </div>
        <div
          className={`${s.paginationContainer} ${isCollapsedSidebar === "true" ? s.collapsed : ""}`}
        >
          {!flowsListPending && totalCount > 0 && (
            <div className={s.newRecordsCount}>
              {t("total")}: {!flowsListPending && <span>{totalCount}</span>}
            </div>
          )}
          {totalCount > 25 && (
            <Pagination
              currentPage={parseInt(pageValue) || 1}
              setCurrentPage={(page) => {
                updateSearchParams(params.page, page);
              }}
              total={totalCount}
              limit={25}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Flows;
