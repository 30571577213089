import React, { useEffect, useRef, useState } from "react";
import s from "./editUser.module.scss";
import {
  globalDataSelector,
  setAdaptiveMenu,
} from "../../../../redux/slices/globalDataSlice";
import { setTopUpBalanceActive } from "../../../../redux/slices/financeSlice";
import GlobalSvgSelector from "../../../../components/GlobalSvgSelector/GlobalSvgSelector";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsAllowedStatisticsAccess, getIsStaff,
  getIsSuperuser,
} from "../../../../storage/storage";
import { authSelector } from "../../../../redux/slices/authSlice";
import PrimaryBtn from "../../../../components/UiKit/Buttons/PrimaryBorderBtn/PrimaryBorderBtn";
import { useFormik } from "formik";
import * as Yup from "yup";
import GradientBorder from "../../../../components/Blocks/GradientBorder/GradientBorder";
import { redirectDropdownStyles } from "../../../../components/UiKit/Popups/GetLinksPopup/GetLinksPopup";
import ApplicationSelect from "../../../../components/Blocks/Selects/ApplicationSelect";
import {
  changePersonalInfo,
  changeUserPassword,
  compensateUser,
  getInstallPrices,
  getUserInfo,
  getUserTgToken,
  managementSelector,
  setIntallPrice,
  setUserInfoObj,
  switchUserStatus,
} from "../../../../redux/slices/managementSlice";
import NewConfirmPopup from "../../../../components/UiKit/Popups/NewConfirmPopup/NewConfirmPopup";
import {
  snackbar_error,
  snackbar_info,
  snackbar_success,
} from "../../../../utils/vars/staticVariables";
import useCustomSnackbar from "../../../../hooks/useCustomSnackbar";
import { emailExists, sameEmail } from "../../../../utils/vars/errorMessages";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../../../components/partials/LanguageDropdown/LanguageDropdown";

const EditUser = () => {
  const [userIconType, setUserIconType] = useState(null);
  const [activeBlockUserPopup, setActiveBlockUserPopup] = useState(false);

  const [isShowedNewPassword1, setShowedNewPassword1] = useState(false);
  const [isShowedNewPassword2, setShowedNewPassword2] = useState(false);

  const newPassword1InputRef = useRef(null);
  const newPassword2InputRef = useRef(null);

  const { isCollapsedSidebar, isAdaptiveMenuActive } =
    useSelector(globalDataSelector);

  const { standardClickPriceValue } = useSelector(managementSelector);

  const { isPartner } = useSelector(authSelector);

  const {
    installPricesList,
    userInfoObj,
    compensateUserPending,
    changeUserPasswordPending,
    getUserInfoPending,
    switchUserStatusPending,
    setInstallPricePending,
    changePersonalInfoPending,
    getUserTgTokenPending,
  } = useSelector(managementSelector);

  const { t } = useTranslation();

  const { is_active, email, first_name, offer, pushsub, install_price_id } =
    userInfoObj || {};

  const [isActiveUser, setActiveUser] = useState(is_active);

  const dispatch = useDispatch();
  const showSnackbar = useCustomSnackbar();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getInstallPrices());
  }, [dispatch]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const getUserInfoResult = await dispatch(getUserInfo(id));
    };

    fetchUserInfo();
  }, [dispatch, id]);

  useEffect(() => {
    setActiveUser(is_active);
  }, [userInfoObj]);

  useEffect(() => {
    if (isPartner !== null) {
      if (isPartner === true) {
        setUserIconType("partner-user-icon");
      } else if (getIsAllowedStatisticsAccess() && !getIsSuperuser()) {
        setUserIconType("support-icon");
      } else if (getIsSuperuser()) {
        setUserIconType("superuser-icon");
      } else {
        setUserIconType("default-user-icon");
      }
    }
  }, [isPartner]);

  const handleShowPasswordClick = (e, ref, state, action) => {
    e.preventDefault();
    action(!state);

    if (ref.current) {
      ref.current.focus();
    }
  };

  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      password_confirm: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(10, t("passwordMin10Characters"))
        .matches(/[A-Z]/, t("passwordMin1CapitalLetter"))
        .matches(/[a-z]/, t("passwordMin1SmallLetter"))
        .matches(/[0-9]/, t("passwordMin1Digit"))
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          t("password_must_contain_1_special_symbol"),
        )
        .required(t("requiredField")),
      password_confirm: Yup.string()
        .oneOf([Yup.ref("password"), null], t("passwordsMustMatch"))
        .required(t("requiredField")),
    }),
    onSubmit: async (form, { setFieldError }) => {
      const dataObj = {
        password: form.password.trim(),
        user_id: id,
      };

      const changePasswordResult = await dispatch(changeUserPassword(dataObj));

      if (changePasswordResult?.payload?.status === 200) {
        showSnackbar(t("password_changed"), snackbar_success);
        passwordFormik.resetForm();
      } else {
        showSnackbar(t("failed_to_change_password"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const personalInfoFormik = useFormik({
    initialValues: {
      new_email: email,
      new_pushsub: pushsub,
      new_offer: offer,
      user_id: id,
      changed_fields_error: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      new_email: Yup.string()
        .trim()
        .required(t("requiredField"))
        .email(t("incorrectEmailFormat")),
      new_pushsub: Yup.string().trim().required(t("requiredField")),
      new_offer: Yup.string().trim().required(t("requiredField")),
    }),
    onSubmit: async (form, { setFieldError }) => {
      if (
        form.new_email === personalInfoFormik.initialValues.new_email &&
        form.new_pushsub === personalInfoFormik.initialValues.new_pushsub &&
        form.new_offer === personalInfoFormik.initialValues.new_offer
      ) {
        return setFieldError(
          "changed_fields_error",
          t("validation_make_changes"),
        );
      }

      const dataObj = {
        user_id: id,
      };

      if (form.new_email !== personalInfoFormik.initialValues.new_email) {
        dataObj.new_email = form.new_email.trim();
      }

      if (form.new_pushsub !== personalInfoFormik.initialValues.new_pushsub) {
        dataObj.new_pushsub = form.new_pushsub.trim();
      }

      if (form.new_offer !== personalInfoFormik.initialValues.new_offer) {
        dataObj.new_offer = form.new_offer.trim();
      }

      const changePersonalInfoResult = await dispatch(
        changePersonalInfo(dataObj),
      );
      const payload = changePersonalInfoResult?.payload;

      if (payload?.status === 200) {
        showSnackbar(t("personal_info_edited"), snackbar_success);
      } else if (payload?.data?.error === sameEmail) {
        setFieldError("new_email", t("new_email_matches_current_email"));
      } else if (payload?.data?.error === emailExists) {
        setFieldError("new_email", t("user_with_such_email_exists"));
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const compensateFormik = useFormik({
    initialValues: {
      description: "",
      amount: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      description: Yup.string().trim().required(t("requiredField")),
      amount: Yup.string()
        .trim()
        .required(t("requiredField"))
        .matches(/^\d+(\.\d{1,2})?$/, t("only_numeric_values_allowed")),
    }),
    onSubmit: async (form, { setFieldError }) => {
      form.description = form.description.trim();
      form.amount = form.amount.trim();

      const number = parseFloat(form.amount);
      if (isNaN(number)) {
        return setFieldError("amount", t("following_numeric_format_allowed"));
      }

      const dataObj = {
        user_id: id,
        amount: number.toFixed(2),
        description: form.description,
      };

      const compensateUserResult = await dispatch(compensateUser(dataObj));

      if (compensateUserResult?.payload?.status === 201) {
        showSnackbar(
          `${t("compensation_of")} ${number.toFixed(2)} $ ${t("compensation_paid")}`,
          snackbar_success,
        );
        compensateFormik.resetForm();
      } else {
        showSnackbar(t("failed_to_pay_compensation"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const installPriceFormik = useFormik({
    initialValues: {
      promotionValue: installPricesList.find(
        ({ value }) => install_price_id === value,
      ),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    onSubmit: async (form, { setFieldError, setFieldValue }) => {
      if (!form.promotionValue || !form.promotionValue.value) {
        return setFieldError("promotionValue", t("choose_option_placeholder"));
      } else {
        installPriceFormik.setErrors({});
      }

      const dataObj = {
        user_id: id,
        promotion_id: form.promotionValue?.value,
        old_promotion_id: install_price_id
      };

      const setInstallPriceResult = await dispatch(setIntallPrice(dataObj));

      if (
        setInstallPriceResult?.payload?.status === 200 ||
        setInstallPriceResult?.payload?.status === 201
      ) {
        showSnackbar(t("price_per_install_is_set"), snackbar_success);
        compensateFormik.resetForm();
      } else {
        showSnackbar(t("failed_to_set_price_per_install"), snackbar_error);
      }
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  useEffect(() => {
    return () => {
      dispatch(setUserInfoObj(null));
    };
  }, [dispatch]);

  const onSwitchUserStatusHandler = async () => {
    const switchUserStatusResult = await dispatch(switchUserStatus(id));

    if (switchUserStatusResult?.payload?.status === 200) {
      if (switchUserStatusResult?.payload?.data?.success === "User activated") {
        showSnackbar(t("user_unblock"), snackbar_success);
      } else {
        showSnackbar(t("user_block"), snackbar_success);
      }
      setActiveUser(!isActiveUser);
    } else {
      if (isActiveUser) {
        showSnackbar(t("failed_to_block_user"), snackbar_error);
      } else {
        showSnackbar(t("failed_to_unblock_user"), snackbar_error);
      }
    }
  };

  const getTgTokenHandler = async () => {
    const getUserTgTokenResult = await dispatch(getUserTgToken(id));

    const payload = getUserTgTokenResult?.payload;

    const tgToken = payload?.data?.telegram_token;

    if (payload?.status === 200) {
      if (tgToken) {
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(tgToken)
            .then(() => {
              showSnackbar(t("token_copied"), snackbar_success);
            })
            .catch(() => {
              showSnackbar(t("failed_to_copy_token"), snackbar_error);
            });
        }
      } else {
        showSnackbar(t("the_token_is_missing"), snackbar_info);
      }
    } else {
      showSnackbar(t("failed_to_get_token"), snackbar_error);
    }
  };

  return (
    <div
      className={`${s.editUserWrapper} ${isCollapsedSidebar === "true" ? s.collapsedSidebar : ""}`}
    >
      <NewConfirmPopup
        icon={<GlobalSvgSelector id="block-user-icon" />}
        active={activeBlockUserPopup}
        setActive={setActiveBlockUserPopup}
        action={() => onSwitchUserStatusHandler()}
        confirmBtnText={isActiveUser ? t("block") : t("unblock")}
        title={isActiveUser ? t("user_lockout") : t("user_unlock")}
        description={`${t("you_want_to")} ${isActiveUser ? t("block").toLowerCase() : t("unblock").toLowerCase()} ${t("block_popup_chosen_user")}?`}
      />
      <div className={s.pageHeaderWrapper}>
        <div className={s.pageHeaderContainer}>
          <div className={s.staticElements}>
            <div className={s.leftSideWrapper}>
              <button
                className={s.walletBtn}
                onClick={() => {
                  dispatch(setAdaptiveMenu(false));
                  dispatch(setTopUpBalanceActive(true));
                }}
              >
                <GlobalSvgSelector id="wallet" />
              </button>
              <Link className={s.walletBtn} to="/account">
                <GlobalSvgSelector id="account-gear-icon" />
              </Link>
              {(getIsStaff() || getIsAllowedStatisticsAccess()) && (
                  <Link to='/management' className={s.standardInstallPrice}>
                    <GlobalSvgSelector id='standard-install-price-icon'/>
                    <span>$ {standardClickPriceValue || "----" }</span>
                  </Link>
              )}
              <LanguageDropdown />
            </div>

            <button
              className={s.menuBtn}
              onClick={() => dispatch(setAdaptiveMenu(!isAdaptiveMenuActive))}
            >
              <GlobalSvgSelector id="burger-menu" />
            </button>
          </div>
        </div>
      </div>
      <div className={s.pageContent}>
        <div className={s.pageMarking}>
          <Link to="/management" className={s.link}>
            {t("users")}
          </Link>
          <GlobalSvgSelector id="arrow-right" />
          <span>{email}</span>
        </div>

        <div className={s.pageInnerContent}>
          <div className={s.contentContainer}>
            <div className={s.userInfoHeader}>
              <div className={s.leftSide}>
                <div className={s.userIconWrapper}>
                  <GlobalSvgSelector id={userIconType} />
                </div>
                <div className={s.nameInfo}>
                  <p>{first_name}</p>
                  <span>{email}</span>
                </div>
              </div>
              <div className={s.rightSide}>
                <button
                  className={s.blueBorderIconBtn}
                  onClick={getTgTokenHandler}
                  disabled={getUserInfoPending || getUserTgTokenPending}
                >
                  <GlobalSvgSelector id="copy-icon" />
                  {t("copy_bot_token")}
                </button>
                {isActiveUser === true && (
                  <button
                    className={s.redBtn}
                    onClick={() => setActiveBlockUserPopup(true)}
                    disabled={getUserInfoPending || switchUserStatusPending}
                  >
                    {t("block")}
                  </button>
                )}

                {isActiveUser === false && (
                  <button
                    className={s.primaryBtn}
                    onClick={() => setActiveBlockUserPopup(true)}
                    disabled={getUserInfoPending || switchUserStatusPending}
                  >
                    {t("unblock")}
                  </button>
                )}
              </div>
            </div>

            <div className={s.formsWrapper}>
              <div className={s.formsColumnContainer1}>
                <form
                  className={s.form}
                  onSubmit={personalInfoFormik.handleSubmit}
                >
                  <p className={s.title}>
                    <GlobalSvgSelector id="personal-account-white-icon" />
                    {t("personal_info")}
                  </p>
                  <GradientBorder className={s.gradientBorderCustom} />
                  <div className={s.inputsWrapper}>
                    <div className={s.inputWrapper}>
                      <p>{t("email")}</p>
                      <input
                        type="text"
                        name="new_email"
                        placeholder={t("type_email")}
                        value={personalInfoFormik.values.new_email}
                        onChange={personalInfoFormik.handleChange}
                        onBlur={personalInfoFormik.handleBlur}
                        className={`${s.textInput} ${
                          personalInfoFormik.touched.new_email &&
                          personalInfoFormik.errors.new_email
                            ? s.error
                            : ""
                        }`}
                      />
                      {personalInfoFormik.touched.new_email &&
                      personalInfoFormik.errors.new_email ? (
                        <div className={s.error}>
                          {personalInfoFormik.errors.new_email}
                        </div>
                      ) : null}
                    </div>
                    <div className={s.inputWrapper}>
                      <p>{t("pushsub")}</p>
                      <input
                        type="text"
                        placeholder={t("type_pushsub")}
                        name="new_pushsub"
                        value={personalInfoFormik.values.new_pushsub}
                        onChange={personalInfoFormik.handleChange}
                        onBlur={personalInfoFormik.handleBlur}
                        className={`${s.textInput} ${
                          personalInfoFormik.touched.new_pushsub &&
                          personalInfoFormik.errors.new_pushsub
                            ? s.error
                            : ""
                        }`}
                      />
                      {personalInfoFormik.touched.new_pushsub &&
                      personalInfoFormik.errors.new_pushsub ? (
                        <div className={s.error}>
                          {personalInfoFormik.errors.new_pushsub}
                        </div>
                      ) : null}
                    </div>
                    <div className={s.inputWrapper}>
                      <p>{t("offer")}</p>
                      <input
                        type="text"
                        placeholder={t("type_offer")}
                        name="new_offer"
                        value={personalInfoFormik.values.new_offer}
                        onChange={personalInfoFormik.handleChange}
                        onBlur={personalInfoFormik.handleBlur}
                        className={`${s.textInput} ${
                          personalInfoFormik.touched.new_offer &&
                          personalInfoFormik.errors.new_offer
                            ? s.error
                            : ""
                        }`}
                      />
                      {personalInfoFormik.touched.new_offer &&
                      personalInfoFormik.errors.new_offer ? (
                        <div className={s.error}>
                          {personalInfoFormik.errors.new_offer}
                        </div>
                      ) : null}
                      {personalInfoFormik.errors.changed_fields_error && (
                        <div className={s.error}>
                          {personalInfoFormik.errors.changed_fields_error}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={s.buttons}>
                    <button
                      className={s.whiteBorderBtn}
                      type="button"
                      onClick={() => personalInfoFormik.resetForm()}
                    >
                      {t("button_cancel")}
                    </button>
                    <PrimaryBtn
                      text={t("button_save")}
                      submit={true}
                      pending={getUserInfoPending || changePersonalInfoPending}
                      className={s.customPrimaryBtn}
                    />
                  </div>
                </form>
                <form
                  className={s.form}
                  onSubmit={installPriceFormik.handleSubmit}
                >
                  <p className={s.title}>
                    <GlobalSvgSelector id="price-install-icon" />
                    {t("set_price_per_install")}
                  </p>
                  <GradientBorder className={s.gradientBorderCustom} />
                  <div className={s.inputsWrapper}>
                    <div className={s.inputWrapper}>
                      <p>{t('price')}</p>
                      <ApplicationSelect
                        value={installPriceFormik.values.promotionValue}
                        isClearable={false}
                        options={installPricesList}
                        onChange={(option) => {
                          installPriceFormik.setFieldValue(
                            "promotionValue",
                            option,
                          );
                        }}
                        isInvalid={installPriceFormik.errors.promotionValue}
                        isMulti={false}
                        placeholderProp={t("choose_option_placeholder")}
                        styles={redirectDropdownStyles}
                      />
                      {installPriceFormik.errors.promotionValue ? (
                        <div className={s.error}>
                          {installPriceFormik.errors.promotionValue}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={s.buttons}>
                    <button
                      className={s.whiteBorderBtn}
                      type="button"
                      onClick={() => installPriceFormik.resetForm()}
                    >
                      {t("button_cancel")}
                    </button>
                    <PrimaryBtn
                      text={t("button_save")}
                      submit={true}
                      pending={getUserInfoPending || setInstallPricePending}
                      className={s.customPrimaryBtn}
                    />
                  </div>
                </form>
              </div>
              <div className={s.formsColumnContainer2}>
                <form
                  className={s.changePasswordForm}
                  onSubmit={passwordFormik.handleSubmit}
                >
                  <p className={s.title}>
                    <GlobalSvgSelector id="change-password-icon" />
                    {t("password_change")}
                  </p>
                  <GradientBorder className={s.gradientBorderCustom} />
                  <div className={s.inputsWrapper}>
                    <div className={s.fieldWrapper}>
                      <p>{t("newPassword")}</p>
                      <div className={s.inputWrapper}>
                        <input
                          name="password"
                          type={isShowedNewPassword1 ? "text" : "password"}
                          placeholder={t("type_password")}
                          value={passwordFormik.values.password}
                          onChange={passwordFormik.handleChange}
                          onBlur={passwordFormik.handleBlur}
                          className={`${s.textInput} ${
                            passwordFormik.touched.password &&
                            passwordFormik.errors.password
                              ? s.error
                              : ""
                          }`}
                        />
                        {passwordFormik.touched.password &&
                        passwordFormik.errors.password ? (
                          <div className={s.error}>
                            {passwordFormik.errors.password}
                          </div>
                        ) : null}
                        <button
                          type="button"
                          className={s.showPasswordBtn}
                          onClick={(e) =>
                            handleShowPasswordClick(
                              e,
                              newPassword1InputRef,
                              isShowedNewPassword1,
                              setShowedNewPassword1,
                            )
                          }
                        >
                          <GlobalSvgSelector
                            id={
                              isShowedNewPassword1
                                ? "show-password-icon"
                                : "hide-password-icon"
                            }
                          />
                        </button>
                      </div>
                    </div>

                    <div className={s.fieldWrapper}>
                      <p>{t("repeatPassword")}</p>
                      <div className={s.inputWrapper}>
                        <input
                          name="password_confirm"
                          type={isShowedNewPassword2 ? "text" : "password"}
                          placeholder={t("type_password")}
                          value={passwordFormik.values.password_confirm}
                          onChange={passwordFormik.handleChange}
                          onBlur={passwordFormik.handleBlur}
                          className={`${s.textInput} ${
                            passwordFormik.touched.password_confirm &&
                            passwordFormik.errors.password_confirm
                              ? s.error
                              : ""
                          }`}
                        />
                        {passwordFormik.touched.password_confirm &&
                        passwordFormik.errors.password_confirm ? (
                          <div className={s.error}>
                            {passwordFormik.errors.password_confirm}
                          </div>
                        ) : null}
                        <button
                          type="button"
                          className={s.showPasswordBtn}
                          onClick={(e) =>
                            handleShowPasswordClick(
                              e,
                              newPassword2InputRef,
                              isShowedNewPassword2,
                              setShowedNewPassword2,
                            )
                          }
                        >
                          <GlobalSvgSelector
                            id={
                              isShowedNewPassword2
                                ? "show-password-icon"
                                : "hide-password-icon"
                            }
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={s.buttons}>
                    <button
                      className={s.whiteBorderBtn}
                      type="button"
                      onClick={() => passwordFormik.resetForm()}
                    >
                      {t("button_cancel")}
                    </button>
                    <PrimaryBtn
                      text={t("button_save")}
                      submit={true}
                      pending={changeUserPasswordPending || getUserInfoPending}
                      className={s.customPrimaryBtn}
                    />
                  </div>
                </form>

                <form
                  className={s.form}
                  onSubmit={compensateFormik.handleSubmit}
                >
                  <p className={s.title}>
                    <GlobalSvgSelector id="compensation-icon" />
                    {t("issue_compensation")}
                  </p>
                  <GradientBorder className={s.gradientBorderCustom} />
                  <div className={s.inputsWrapper}>
                    <div className={s.inputWrapper}>
                      <p>{t("cause_of_compensation")}</p>
                      <input
                        type="text"
                        placeholder={t("placehoder_type_text")}
                        name="description"
                        value={compensateFormik.values.description}
                        onChange={compensateFormik.handleChange}
                        onBlur={compensateFormik.handleBlur}
                        className={`${s.textInput} ${
                          compensateFormik.touched.description &&
                          compensateFormik.errors.description
                            ? s.error
                            : ""
                        }`}
                      />
                      {compensateFormik.touched.description &&
                      compensateFormik.errors.description ? (
                        <div className={s.error}>
                          {compensateFormik.errors.description}
                        </div>
                      ) : null}
                    </div>
                    <div className={s.inputWrapper}>
                      <p>{t("compensation_amount")} $</p>
                      <input
                        type="text"
                        placeholder={t("type_amount")}
                        name="amount"
                        value={compensateFormik.values.amount}
                        onChange={compensateFormik.handleChange}
                        onBlur={compensateFormik.handleBlur}
                        className={`${s.textInput} ${
                          compensateFormik.touched.amount &&
                          compensateFormik.errors.amount
                            ? s.error
                            : ""
                        }`}
                      />
                      {compensateFormik.touched.amount &&
                      compensateFormik.errors.amount ? (
                        <div className={s.error}>
                          {compensateFormik.errors.amount}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={s.buttons}>
                    <button
                      className={s.whiteBorderBtn}
                      type="button"
                      onClick={() => compensateFormik.resetForm()}
                    >
                      {t("button_cancel")}
                    </button>
                    <PrimaryBtn
                      text={t("pay_out")}
                      submit={true}
                      pending={compensateUserPending || getUserInfoPending}
                      className={s.customPrimaryBtn}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
