import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "../../api/url";
import {
  prelandingStatusList,
  prelandingTypesList,
} from "../../utils/vars/staticVariables";
import { useState } from "react";

export const fetchFlowById = createAsyncThunk(
  "flowSettingsSlice/fetchFlowById",
  async (id) => {
    try {
      const { data } = await $api.get(`/configuration/flows/${id}/`);

      return data;
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const fetchDomainZones = createAsyncThunk(
  "flowSettingsSlice/fetchDomainZones",
  async () => {
    try {
      const { data } = await $api.get("/domains/domains/domains_zone_list/");
      return data;
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const setSubDomain = createAsyncThunk(
  "flowSettingsSlice/setSubDomain",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/configuration/flow/create/set_subdomain/",
        dataObj,
      );
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const createFlow = createAsyncThunk(
  "flowSettingsSlice/createFlow",
  async (domainZoneOption) => {
    try {
      let url = "/configuration/flow/create/";
      if (domainZoneOption?.value) {
        url += `?domain_zone=${domainZoneOption?.value}`;
      }

      const response = await $api.get(url);
      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const setWhitePageSettings = createAsyncThunk(
  "flowSettingsSlice/setWhitePageSettings",
  async (dataObj) => {
    try {
      const response = await $api.patch(
        "/configuration/flow/create/set_settings/",
        dataObj,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const setFlowRedirect = createAsyncThunk(
  "flowSettingsSlice/changeFlowRedirect",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/configuration/flow/change-redirect-by-id/",
        dataObj,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

export const setFlowPrelanding = createAsyncThunk(
  "flowSettingsSlice/setFlowPrelanding",
  async (dataObj) => {
    try {
      const response = await $api.post(
        "/prelanding/change-prelanding/",
        dataObj,
      );

      const { data, status } = response;

      return { data, status };
    } catch (error) {
      const { data, status } = error.response;

      return { data, status };
    }
  },
);

const initialCreatePrelandingFormValues = {
  status: prelandingStatusList[0].value,
  name: "",
  num_of_revolutions: 1,
  prelanding_type: prelandingTypesList[0].value,
  header_text: "Welcome",
  rotation_button_text: "Spin",
  respin_button_text: "Spin",
  style: "",
  sectors: new Array(8).fill().map((_, index) => ({
    name: `Сектор ${index}`,
    value: "",
    is_win: index <= 0,
  })),
  bonus_button_text: "ОК",
  popup_win_text: "You get {bonus}",
  winning_button_text: "Get",
  logo: null,
};

const flowSettingsSlice = createSlice({
  name: "flowSettingsSlice",
  initialState: {
    domainZonesList: [],
    setSubDomainPending: false,
    createFlowPending: false,

    createPrelandingFormValues: initialCreatePrelandingFormValues,

    prelandTabContent: "choose",
    // const [prelandTabContent, setPrelandTabContent] = useState("choose");

    currentEditPrelandingObj: null,

    visiblePrelandings: 8,

    setFlowPrelandingPending: false,

    flowObj: null,
    fetchFlowByIdPending: false,

    setWhitePageSettingsPending: false,
    setFlowRedirectPending: false,
  },
  reducers: {
    setCurrentEditPrelandingObj: (state, action) => {
      state.currentEditPrelandingObj = action.payload;
    },
    setFlowObj: (state, action) => {
      state.flowObj = action.payload;
    },
    incrementVisiblePrelandings: (state, action) => {
      state.visiblePrelandings += action.payload;
    },
    resetVisiblePrelandings: (state) => {
      state.visiblePrelandings = 16;
    },
    setPrelandTabContent: (state, action) => {
      state.prelandTabContent = action.payload;
    },
    setCreatePrelandingFormValues: (state, action) => {
      state.createPrelandingFormValues = action.payload;
    },
    resetCreatePrelandingFormValues: (state) => {
      state.createPrelandingFormValues = initialCreatePrelandingFormValues;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setWhitePageSettings.pending, (state) => {
        state.setWhitePageSettingsPending = true;
      })
      .addCase(setWhitePageSettings.fulfilled, (state) => {
        state.setWhitePageSettingsPending = false;
      })
      .addCase(setWhitePageSettings.rejected, (state) => {
        state.setWhitePageSettingsPending = true;
      })

      .addCase(setFlowRedirect.pending, (state) => {
        state.setFlowRedirectPending = true;
      })
      .addCase(setFlowRedirect.fulfilled, (state) => {
        state.setFlowRedirectPending = false;
      })
      .addCase(setFlowRedirect.rejected, (state) => {
        state.setFlowRedirectPending = true;
      })

      .addCase(setFlowPrelanding.pending, (state) => {
        state.setFlowPrelandingPending = true;
      })
      .addCase(setFlowPrelanding.fulfilled, (state) => {
        state.setFlowPrelandingPending = false;
      })
      .addCase(setFlowPrelanding.rejected, (state) => {
        state.setFlowPrelandingPending = true;
      })

      .addCase(fetchDomainZones.pending, (state) => {
        state.domainZonesList = [];
      })
      .addCase(fetchDomainZones.fulfilled, (state, action) => {
        state.domainZonesList = action.payload?.domains_zone;
      })
      .addCase(fetchDomainZones.rejected, (state) => {
        state.domainZonesList = [];
      })

      .addCase(fetchFlowById.pending, (state) => {
        state.flowObj = null;
        state.fetchFlowByIdPending = true;
      })
      .addCase(fetchFlowById.fulfilled, (state, action) => {
        state.flowObj = action.payload;
        state.fetchFlowByIdPending = false;
      })
      .addCase(fetchFlowById.rejected, (state) => {
        state.flowObj = null;
        state.fetchFlowByIdPending = false;
      })

      .addCase(setSubDomain.pending, (state) => {
        state.setSubDomainPending = true;
      })
      .addCase(setSubDomain.fulfilled, (state) => {
        state.setSubDomainPending = false;
      })
      .addCase(setSubDomain.rejected, (state) => {
        state.setSubDomainPending = false;
      })

      .addCase(createFlow.pending, (state) => {
        state.createFlowPending = true;
      })
      .addCase(createFlow.fulfilled, (state) => {
        state.createFlowPending = false;
      })
      .addCase(createFlow.rejected, (state) => {
        state.createFlowPending = false;
      });
  },
});

export const {
  setFlowObj,
  setCreatePrelandingFormValues,
  setPrelandTabContent,
  resetCreatePrelandingFormValues,
  setCurrentEditPrelandingObj,
  incrementVisiblePrelandings,
  resetVisiblePrelandings,
} = flowSettingsSlice.actions;

export default flowSettingsSlice.reducer;

export const flowSettingsSelector = (state) => state.flowSettings;
