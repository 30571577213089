import React from "react";
import Select from "react-select";
import {SelectStyles} from "./themes";
import {useTranslation} from "react-i18next";

const ReactSelectTheme = ({
                              value,
                              options,
                              action,
                              isMulti,
                              placeholder,
                              darkTheme,
                              isClearable = false,
                              isInvalid,
                              isDisabled,
                                onDisabledClick
                          }) => {
    const {t} = useTranslation();

    return (
        <div onClick={() => {
            if (onDisabledClick && isDisabled) {
                onDisabledClick()
            }
        }}>
            <Select
            value={value}
            isClearable={isClearable}
            options={options}
            onChange={(o) => {
                action(o);
            }}
            isMulti={isMulti}
            placeholder={placeholder}
            styles={SelectStyles}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            darkTheme={darkTheme}
            noOptionsMessage={() => t("no_options")}
        />
        </div>
    );
};

export default ReactSelectTheme;
